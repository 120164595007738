import {
	helpers,
	required,
	email,
    maxLength,
} from "@vuelidate/validators";
  

  const fax = {
    namespaced: true,

    state: {
		listUrl: "/admin/fax-config/list",
        header: [
            { "account_number": "Account Name" },
            { "account_number": "Fax Account" },
            { "is_enable_incoming_fax": "Incoming Faxing" },
            { "is_enable_outgoing_fax": "Outgoing Faxing" },
            { "action": "Action" },
        ],
        pagination: {
			to: "",
			from: "",
			total: "",
			last_page: "",
			current_page: "",
			pageNavArr: [],
		},
		list: [],
		defaultFormData: {
            'is_enable_integration':1,
            'account_number':null,
            'password':null,
            'internal_name':null,
            'is_enable_incoming_fax':1,
            'is_enable_outgoing_fax':1,
            'notification_email':null,
            'response_fax_number':null,
            'cover_letter':'Not Set',
			'is_sr_fax_active':false
        },
        form:{
            'is_enable_integration':1,
            'account_number':null,
            'password':null,
            'internal_name':null,
            'is_enable_incoming_fax':1,
            'is_enable_outgoing_fax':1,
            'notification_email':null,
            'response_fax_number':null,
            'cover_letter':'Not Set',
			'is_sr_fax_active':false
        },
        validationRules: {
			form: {
				account_number: {
					required: helpers.withMessage("Please enter account number.", required),
                    maxLength:helpers.withMessage("Please enter account number 20 char.", maxLength(20)),
				},
                password: {
					required: helpers.withMessage("Please enter password.", required),
                    maxLength:helpers.withMessage("Please enter password 20 char.", maxLength(20)),
				},
                notification_email: {
					required: helpers.withMessage("Please enter notification email.", required),
                    email: helpers.withMessage("Please enter valid notification email.", email),
                    maxLength:helpers.withMessage("Please enter notification email 80 char.", maxLength(80)),
				},
                response_fax_number: {
					required: helpers.withMessage("Please enter response fax number.", required),
                    maxLength:helpers.withMessage("Please enter response fax number 20 char.", maxLength(20)),
				},
            }
        },
        filter:{
            'user_id':localStorage.getItem("userId")
        }

    }
}

const sendFax = {
	namespaced: true,
	state: {
		defaultFormData: {
			recipient: null,
			cover_page: null,
			to_name: null,
			organization: null,
			subject: null,
			file: null,
			cover_letter:'Basic',
			phoneNumbers:[]
		},
		form:{
			recipient: null,
			cover_page: null,
			to_name: null,
			organization: null,
			subject: null,
			file: null,
			cover_letter:'Basic',
			phoneNumbers:[]
		},
		validationRules: {
			form: {
				phoneNumbers: {
					required: helpers.withMessage("Please enter recipient.", required),
				},
				file: {
					required: helpers.withMessage("Please select atleast one file.", required),
				},
			},
		}
	}
}
export {fax, sendFax};
